// generated by parserlib; do not edit.
import {
  textForSpan,
  childByName,
  childrenByName,
  RuleTree,
  extractRuleTree,
} from "../../parserlib/ruleTree";
import { Span, Grammar, ParseError } from "../../parserlib/types";
import * as parserlib from "../../parserlib/parser";
export type DL2Aggregation = {
  type: "Aggregation";
  text: string;
  span: Span;
  aggregation: DL2Ident;
  var: DL2Var[];
  commaSpace: DL2CommaSpace[];
  record: DL2Record;
};
export type DL2Alpha = {
  type: "Alpha";
  text: string;
  span: Span;
};
export type DL2AlphaNum = DL2Alpha | DL2Num;
export type DL2Arithmetic = DL2AssignmentOnLeft | DL2AssignmentOnRight;
export type DL2ArithmeticOp = {
  type: "ArithmeticOp";
  text: string;
  span: Span;
};
export type DL2Array = {
  type: "Array";
  text: string;
  span: Span;
  term: DL2Term[];
  commaSpace: DL2CommaSpace[];
};
export type DL2AssignmentOnLeft = {
  type: "AssignmentOnLeft";
  text: string;
  span: Span;
  result: DL2Term;
  left: DL2Term;
  arithmeticOp: DL2ArithmeticOp;
  right: DL2Term;
};
export type DL2AssignmentOnRight = {
  type: "AssignmentOnRight";
  text: string;
  span: Span;
  left: DL2Term;
  arithmeticOp: DL2ArithmeticOp;
  right: DL2Term;
  result: DL2Term;
};
export type DL2Bool = {
  type: "Bool";
  text: string;
  span: Span;
};
export type DL2CommaSpace = {
  type: "CommaSpace";
  text: string;
  span: Span;
};
export type DL2Comment = {
  type: "Comment";
  text: string;
  span: Span;
  commentChar: DL2CommentChar[];
};
export type DL2CommentChar = {
  type: "CommentChar";
  text: string;
  span: Span;
};
export type DL2Comparison = {
  type: "Comparison";
  text: string;
  span: Span;
  left: DL2Term;
  comparisonOp: DL2ComparisonOp;
  right: DL2Term;
};
export type DL2ComparisonOp = {
  type: "ComparisonOp";
  text: string;
  span: Span;
};
export type DL2Conjunct =
  | DL2Nested
  | DL2Record
  | DL2Comparison
  | DL2Arithmetic
  | DL2Negation
  | DL2Aggregation
  | DL2Placeholder;
export type DL2Declaration = DL2Rule | DL2TableDecl | DL2Import | DL2Fact;
export type DL2DefKW = {
  type: "DefKW";
  text: string;
  span: Span;
};
export type DL2DeleteFact = {
  type: "DeleteFact";
  text: string;
  span: Span;
  record: DL2Record;
};
export type DL2Dict = {
  type: "Dict";
  text: string;
  span: Span;
  dictKeyValue: DL2DictKeyValue[];
  commaSpace: DL2CommaSpace[];
};
export type DL2DictKeyValue = {
  type: "DictKeyValue";
  text: string;
  span: Span;
  key: DL2String;
  value: DL2Term;
};
export type DL2Disjunct = {
  type: "Disjunct";
  text: string;
  span: Span;
  conjunct: DL2Conjunct[];
};
export type DL2Fact = {
  type: "Fact";
  text: string;
  span: Span;
  record: DL2Record;
};
export type DL2Ident = {
  type: "Ident";
  text: string;
  span: Span;
  alpha: DL2Alpha;
  alphaNum: DL2AlphaNum[];
};
export type DL2Import = {
  type: "Import";
  text: string;
  span: Span;
  importKW: DL2ImportKW;
  path: DL2Path;
};
export type DL2ImportKW = {
  type: "ImportKW";
  text: string;
  span: Span;
};
export type DL2InRef = {
  type: "InRef";
  text: string;
  span: Span;
  inRefKW: DL2InRefKW;
  table: DL2Qualifier;
  col: DL2Ident;
};
export type DL2InRefKW = {
  type: "InRefKW";
  text: string;
  span: Span;
};
export type DL2Int = {
  type: "Int";
  text: string;
  span: Span;
  first: DL2Num;
  num: DL2Num[];
};
export type DL2Main = {
  type: "Main";
  text: string;
  span: Span;
  declaration: DL2Declaration[];
};
export type DL2Negation = {
  type: "Negation";
  text: string;
  span: Span;
  record: DL2Record;
};
export type DL2Nested = {
  type: "Nested";
  text: string;
  span: Span;
  qualifier: DL2Qualifier;
  nestedAttr: DL2NestedAttr[];
  commaSpace: DL2CommaSpace[];
};
export type DL2NestedAttr = DL2NormalAttr | DL2Nested;
export type DL2NormalAttr = {
  type: "NormalAttr";
  text: string;
  span: Span;
  ident: DL2Ident;
  term: DL2Term;
};
export type DL2Num = {
  type: "Num";
  text: string;
  span: Span;
};
export type DL2OutRef = {
  type: "OutRef";
  text: string;
  span: Span;
  outRefKW: DL2OutRefKW;
  table: DL2Qualifier;
  col: DL2Ident;
};
export type DL2OutRefKW = {
  type: "OutRefKW";
  text: string;
  span: Span;
};
export type DL2Path = {
  type: "Path";
  text: string;
  span: Span;
  pathSegment: DL2PathSegment[];
};
export type DL2PathSegment = {
  type: "PathSegment";
  text: string;
  span: Span;
};
export type DL2Placeholder = {
  type: "Placeholder";
  text: string;
  span: Span;
};
export type DL2Qualifier = {
  type: "Qualifier";
  text: string;
  span: Span;
  ident: DL2Ident[];
};
export type DL2Query = {
  type: "Query";
  text: string;
  span: Span;
  record: DL2Record;
};
export type DL2Record = {
  type: "Record";
  text: string;
  span: Span;
  qualifier: DL2Qualifier;
  recordAttrs: DL2RecordAttrs;
};
export type DL2RecordAttrs = {
  type: "RecordAttrs";
  text: string;
  span: Span;
  recordKeyValue: DL2RecordKeyValue[];
  placeholder: DL2Placeholder[];
  commaSpace: DL2CommaSpace[];
};
export type DL2RecordKeyValue = {
  type: "RecordKeyValue";
  text: string;
  span: Span;
  ident: DL2Ident;
  term: DL2Term;
};
export type DL2RefSpec = {
  type: "RefSpec";
  text: string;
  span: Span;
  outRef: DL2OutRef | null;
  inRef: DL2InRef | null;
};
export type DL2Rule = {
  type: "Rule";
  text: string;
  span: Span;
  defKW: DL2DefKW;
  record: DL2Record;
  disjunct: DL2Disjunct[];
};
export type DL2Spaces = {
  type: "Spaces";
  text: string;
  span: Span;
};
export type DL2String = {
  type: "String";
  text: string;
  span: Span;
  stringChar: DL2StringChar[];
};
export type DL2StringChar = {
  type: "StringChar";
  text: string;
  span: Span;
};
export type DL2TableAttr = {
  type: "TableAttr";
  text: string;
  span: Span;
  ident: DL2Ident;
  refSpec: DL2RefSpec | null;
};
export type DL2TableDecl = {
  type: "TableDecl";
  text: string;
  span: Span;
  tableKW: DL2TableKW;
  name: DL2Qualifier;
  tableAttr: DL2TableAttr[];
  commaSpace: DL2CommaSpace[];
};
export type DL2TableKW = {
  type: "TableKW";
  text: string;
  span: Span;
};
export type DL2Term =
  | DL2Record
  | DL2Int
  | DL2Var
  | DL2String
  | DL2Bool
  | DL2Array
  | DL2Dict
  | DL2Placeholder;
export type DL2Var = {
  type: "Var";
  text: string;
  span: Span;
  alphaNum: DL2AlphaNum[];
};
export type DL2Ws = {
  type: "Ws";
  text: string;
  span: Span;
  spaces: DL2Spaces[];
  comment: DL2Comment[];
};
export function parseAggregation(
  input: string
): [DL2Aggregation, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "aggregation", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractAggregation(input, ruleTree);
  return [extracted, errors];
}
export function parseAlpha(input: string): [DL2Alpha, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "alpha", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractAlpha(input, ruleTree);
  return [extracted, errors];
}
export function parseAlphaNum(input: string): [DL2AlphaNum, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "alphaNum", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractAlphaNum(input, ruleTree);
  return [extracted, errors];
}
export function parseArithmetic(input: string): [DL2Arithmetic, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "arithmetic", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractArithmetic(input, ruleTree);
  return [extracted, errors];
}
export function parseArithmeticOp(
  input: string
): [DL2ArithmeticOp, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "arithmeticOp", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractArithmeticOp(input, ruleTree);
  return [extracted, errors];
}
export function parseArray(input: string): [DL2Array, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "array", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractArray(input, ruleTree);
  return [extracted, errors];
}
export function parseAssignmentOnLeft(
  input: string
): [DL2AssignmentOnLeft, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "assignmentOnLeft", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractAssignmentOnLeft(input, ruleTree);
  return [extracted, errors];
}
export function parseAssignmentOnRight(
  input: string
): [DL2AssignmentOnRight, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "assignmentOnRight", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractAssignmentOnRight(input, ruleTree);
  return [extracted, errors];
}
export function parseBool(input: string): [DL2Bool, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "bool", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractBool(input, ruleTree);
  return [extracted, errors];
}
export function parseCommaSpace(input: string): [DL2CommaSpace, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "commaSpace", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractCommaSpace(input, ruleTree);
  return [extracted, errors];
}
export function parseComment(input: string): [DL2Comment, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "comment", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractComment(input, ruleTree);
  return [extracted, errors];
}
export function parseCommentChar(
  input: string
): [DL2CommentChar, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "commentChar", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractCommentChar(input, ruleTree);
  return [extracted, errors];
}
export function parseComparison(input: string): [DL2Comparison, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "comparison", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractComparison(input, ruleTree);
  return [extracted, errors];
}
export function parseComparisonOp(
  input: string
): [DL2ComparisonOp, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "comparisonOp", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractComparisonOp(input, ruleTree);
  return [extracted, errors];
}
export function parseConjunct(input: string): [DL2Conjunct, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "conjunct", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractConjunct(input, ruleTree);
  return [extracted, errors];
}
export function parseDeclaration(
  input: string
): [DL2Declaration, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "declaration", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractDeclaration(input, ruleTree);
  return [extracted, errors];
}
export function parseDefKW(input: string): [DL2DefKW, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "defKW", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractDefKW(input, ruleTree);
  return [extracted, errors];
}
export function parseDeleteFact(input: string): [DL2DeleteFact, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "deleteFact", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractDeleteFact(input, ruleTree);
  return [extracted, errors];
}
export function parseDict(input: string): [DL2Dict, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "dict", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractDict(input, ruleTree);
  return [extracted, errors];
}
export function parseDictKeyValue(
  input: string
): [DL2DictKeyValue, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "dictKeyValue", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractDictKeyValue(input, ruleTree);
  return [extracted, errors];
}
export function parseDisjunct(input: string): [DL2Disjunct, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "disjunct", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractDisjunct(input, ruleTree);
  return [extracted, errors];
}
export function parseFact(input: string): [DL2Fact, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "fact", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractFact(input, ruleTree);
  return [extracted, errors];
}
export function parseIdent(input: string): [DL2Ident, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "ident", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractIdent(input, ruleTree);
  return [extracted, errors];
}
export function parseImport(input: string): [DL2Import, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "import", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractImport(input, ruleTree);
  return [extracted, errors];
}
export function parseImportKW(input: string): [DL2ImportKW, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "importKW", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractImportKW(input, ruleTree);
  return [extracted, errors];
}
export function parseInRef(input: string): [DL2InRef, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "inRef", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractInRef(input, ruleTree);
  return [extracted, errors];
}
export function parseInRefKW(input: string): [DL2InRefKW, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "inRefKW", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractInRefKW(input, ruleTree);
  return [extracted, errors];
}
export function parseInt(input: string): [DL2Int, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "int", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractInt(input, ruleTree);
  return [extracted, errors];
}
export function parseMain(input: string): [DL2Main, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "main", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractMain(input, ruleTree);
  return [extracted, errors];
}
export function parseNegation(input: string): [DL2Negation, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "negation", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractNegation(input, ruleTree);
  return [extracted, errors];
}
export function parseNested(input: string): [DL2Nested, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "nested", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractNested(input, ruleTree);
  return [extracted, errors];
}
export function parseNestedAttr(input: string): [DL2NestedAttr, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "nestedAttr", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractNestedAttr(input, ruleTree);
  return [extracted, errors];
}
export function parseNormalAttr(input: string): [DL2NormalAttr, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "normalAttr", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractNormalAttr(input, ruleTree);
  return [extracted, errors];
}
export function parseNum(input: string): [DL2Num, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "num", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractNum(input, ruleTree);
  return [extracted, errors];
}
export function parseOutRef(input: string): [DL2OutRef, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "outRef", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractOutRef(input, ruleTree);
  return [extracted, errors];
}
export function parseOutRefKW(input: string): [DL2OutRefKW, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "outRefKW", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractOutRefKW(input, ruleTree);
  return [extracted, errors];
}
export function parsePath(input: string): [DL2Path, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "path", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractPath(input, ruleTree);
  return [extracted, errors];
}
export function parsePathSegment(
  input: string
): [DL2PathSegment, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "pathSegment", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractPathSegment(input, ruleTree);
  return [extracted, errors];
}
export function parsePlaceholder(
  input: string
): [DL2Placeholder, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "placeholder", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractPlaceholder(input, ruleTree);
  return [extracted, errors];
}
export function parseQualifier(input: string): [DL2Qualifier, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "qualifier", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractQualifier(input, ruleTree);
  return [extracted, errors];
}
export function parseQuery(input: string): [DL2Query, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "query", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractQuery(input, ruleTree);
  return [extracted, errors];
}
export function parseRecord(input: string): [DL2Record, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "record", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractRecord(input, ruleTree);
  return [extracted, errors];
}
export function parseRecordAttrs(
  input: string
): [DL2RecordAttrs, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "recordAttrs", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractRecordAttrs(input, ruleTree);
  return [extracted, errors];
}
export function parseRecordKeyValue(
  input: string
): [DL2RecordKeyValue, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "recordKeyValue", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractRecordKeyValue(input, ruleTree);
  return [extracted, errors];
}
export function parseRefSpec(input: string): [DL2RefSpec, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "refSpec", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractRefSpec(input, ruleTree);
  return [extracted, errors];
}
export function parseRule(input: string): [DL2Rule, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "rule", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractRule(input, ruleTree);
  return [extracted, errors];
}
export function parseSpaces(input: string): [DL2Spaces, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "spaces", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractSpaces(input, ruleTree);
  return [extracted, errors];
}
export function parseString(input: string): [DL2String, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "string", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractString(input, ruleTree);
  return [extracted, errors];
}
export function parseStringChar(input: string): [DL2StringChar, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "stringChar", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractStringChar(input, ruleTree);
  return [extracted, errors];
}
export function parseTableAttr(input: string): [DL2TableAttr, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "tableAttr", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractTableAttr(input, ruleTree);
  return [extracted, errors];
}
export function parseTableDecl(input: string): [DL2TableDecl, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "tableDecl", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractTableDecl(input, ruleTree);
  return [extracted, errors];
}
export function parseTableKW(input: string): [DL2TableKW, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "tableKW", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractTableKW(input, ruleTree);
  return [extracted, errors];
}
export function parseTerm(input: string): [DL2Term, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "term", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractTerm(input, ruleTree);
  return [extracted, errors];
}
export function parseVar(input: string): [DL2Var, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "var", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractVar(input, ruleTree);
  return [extracted, errors];
}
function extractAggregation(input: string, node: RuleTree): DL2Aggregation {
  return {
    type: "Aggregation",
    text: textForSpan(input, node.span),
    span: node.span,
    aggregation: extractIdent(input, childByName(node, "ident", "aggregation")),
    var: childrenByName(node, "var").map((child) => extractVar(input, child)),
    commaSpace: childrenByName(node, "commaSpace").map((child) =>
      extractCommaSpace(input, child)
    ),
    record: extractRecord(input, childByName(node, "record", null)),
  };
}
function extractAlpha(input: string, node: RuleTree): DL2Alpha {
  return {
    type: "Alpha",
    text: textForSpan(input, node.span),
    span: node.span,
  };
}
function extractAlphaNum(input: string, node: RuleTree): DL2AlphaNum {
  const child = node.children[0];
  switch (child.name) {
    case "alpha": {
      return extractAlpha(input, child);
    }
    case "num": {
      return extractNum(input, child);
    }
  }
}
function extractArithmetic(input: string, node: RuleTree): DL2Arithmetic {
  const child = node.children[0];
  switch (child.name) {
    case "assignmentOnLeft": {
      return extractAssignmentOnLeft(input, child);
    }
    case "assignmentOnRight": {
      return extractAssignmentOnRight(input, child);
    }
  }
}
function extractArithmeticOp(input: string, node: RuleTree): DL2ArithmeticOp {
  return {
    type: "ArithmeticOp",
    text: textForSpan(input, node.span),
    span: node.span,
  };
}
function extractArray(input: string, node: RuleTree): DL2Array {
  return {
    type: "Array",
    text: textForSpan(input, node.span),
    span: node.span,
    term: childrenByName(node, "term").map((child) =>
      extractTerm(input, child)
    ),
    commaSpace: childrenByName(node, "commaSpace").map((child) =>
      extractCommaSpace(input, child)
    ),
  };
}
function extractAssignmentOnLeft(
  input: string,
  node: RuleTree
): DL2AssignmentOnLeft {
  return {
    type: "AssignmentOnLeft",
    text: textForSpan(input, node.span),
    span: node.span,
    result: extractTerm(input, childByName(node, "term", "result")),
    left: extractTerm(input, childByName(node, "term", "left")),
    arithmeticOp: extractArithmeticOp(
      input,
      childByName(node, "arithmeticOp", null)
    ),
    right: extractTerm(input, childByName(node, "term", "right")),
  };
}
function extractAssignmentOnRight(
  input: string,
  node: RuleTree
): DL2AssignmentOnRight {
  return {
    type: "AssignmentOnRight",
    text: textForSpan(input, node.span),
    span: node.span,
    left: extractTerm(input, childByName(node, "term", "left")),
    arithmeticOp: extractArithmeticOp(
      input,
      childByName(node, "arithmeticOp", null)
    ),
    right: extractTerm(input, childByName(node, "term", "right")),
    result: extractTerm(input, childByName(node, "term", "result")),
  };
}
function extractBool(input: string, node: RuleTree): DL2Bool {
  return {
    type: "Bool",
    text: textForSpan(input, node.span),
    span: node.span,
  };
}
function extractCommaSpace(input: string, node: RuleTree): DL2CommaSpace {
  return {
    type: "CommaSpace",
    text: textForSpan(input, node.span),
    span: node.span,
  };
}
function extractComment(input: string, node: RuleTree): DL2Comment {
  return {
    type: "Comment",
    text: textForSpan(input, node.span),
    span: node.span,
    commentChar: childrenByName(node, "commentChar").map((child) =>
      extractCommentChar(input, child)
    ),
  };
}
function extractCommentChar(input: string, node: RuleTree): DL2CommentChar {
  return {
    type: "CommentChar",
    text: textForSpan(input, node.span),
    span: node.span,
  };
}
function extractComparison(input: string, node: RuleTree): DL2Comparison {
  return {
    type: "Comparison",
    text: textForSpan(input, node.span),
    span: node.span,
    left: extractTerm(input, childByName(node, "term", "left")),
    comparisonOp: extractComparisonOp(
      input,
      childByName(node, "comparisonOp", null)
    ),
    right: extractTerm(input, childByName(node, "term", "right")),
  };
}
function extractComparisonOp(input: string, node: RuleTree): DL2ComparisonOp {
  return {
    type: "ComparisonOp",
    text: textForSpan(input, node.span),
    span: node.span,
  };
}
function extractConjunct(input: string, node: RuleTree): DL2Conjunct {
  const child = node.children[0];
  switch (child.name) {
    case "nested": {
      return extractNested(input, child);
    }
    case "record": {
      return extractRecord(input, child);
    }
    case "comparison": {
      return extractComparison(input, child);
    }
    case "arithmetic": {
      return extractArithmetic(input, child);
    }
    case "negation": {
      return extractNegation(input, child);
    }
    case "aggregation": {
      return extractAggregation(input, child);
    }
    case "placeholder": {
      return extractPlaceholder(input, child);
    }
  }
}
function extractDeclaration(input: string, node: RuleTree): DL2Declaration {
  const child = node.children[0];
  switch (child.name) {
    case "rule": {
      return extractRule(input, child);
    }
    case "tableDecl": {
      return extractTableDecl(input, child);
    }
    case "import": {
      return extractImport(input, child);
    }
    case "fact": {
      return extractFact(input, child);
    }
  }
}
function extractDefKW(input: string, node: RuleTree): DL2DefKW {
  return {
    type: "DefKW",
    text: textForSpan(input, node.span),
    span: node.span,
  };
}
function extractDeleteFact(input: string, node: RuleTree): DL2DeleteFact {
  return {
    type: "DeleteFact",
    text: textForSpan(input, node.span),
    span: node.span,
    record: extractRecord(input, childByName(node, "record", null)),
  };
}
function extractDict(input: string, node: RuleTree): DL2Dict {
  return {
    type: "Dict",
    text: textForSpan(input, node.span),
    span: node.span,
    dictKeyValue: childrenByName(node, "dictKeyValue").map((child) =>
      extractDictKeyValue(input, child)
    ),
    commaSpace: childrenByName(node, "commaSpace").map((child) =>
      extractCommaSpace(input, child)
    ),
  };
}
function extractDictKeyValue(input: string, node: RuleTree): DL2DictKeyValue {
  return {
    type: "DictKeyValue",
    text: textForSpan(input, node.span),
    span: node.span,
    key: extractString(input, childByName(node, "string", "key")),
    value: extractTerm(input, childByName(node, "term", "value")),
  };
}
function extractDisjunct(input: string, node: RuleTree): DL2Disjunct {
  return {
    type: "Disjunct",
    text: textForSpan(input, node.span),
    span: node.span,
    conjunct: childrenByName(node, "conjunct").map((child) =>
      extractConjunct(input, child)
    ),
  };
}
function extractFact(input: string, node: RuleTree): DL2Fact {
  return {
    type: "Fact",
    text: textForSpan(input, node.span),
    span: node.span,
    record: extractRecord(input, childByName(node, "record", null)),
  };
}
function extractIdent(input: string, node: RuleTree): DL2Ident {
  return {
    type: "Ident",
    text: textForSpan(input, node.span),
    span: node.span,
    alpha: extractAlpha(input, childByName(node, "alpha", null)),
    alphaNum: childrenByName(node, "alphaNum").map((child) =>
      extractAlphaNum(input, child)
    ),
  };
}
function extractImport(input: string, node: RuleTree): DL2Import {
  return {
    type: "Import",
    text: textForSpan(input, node.span),
    span: node.span,
    importKW: extractImportKW(input, childByName(node, "importKW", null)),
    path: extractPath(input, childByName(node, "path", null)),
  };
}
function extractImportKW(input: string, node: RuleTree): DL2ImportKW {
  return {
    type: "ImportKW",
    text: textForSpan(input, node.span),
    span: node.span,
  };
}
function extractInRef(input: string, node: RuleTree): DL2InRef {
  return {
    type: "InRef",
    text: textForSpan(input, node.span),
    span: node.span,
    inRefKW: extractInRefKW(input, childByName(node, "inRefKW", null)),
    table: extractQualifier(input, childByName(node, "qualifier", "table")),
    col: extractIdent(input, childByName(node, "ident", "col")),
  };
}
function extractInRefKW(input: string, node: RuleTree): DL2InRefKW {
  return {
    type: "InRefKW",
    text: textForSpan(input, node.span),
    span: node.span,
  };
}
function extractInt(input: string, node: RuleTree): DL2Int {
  return {
    type: "Int",
    text: textForSpan(input, node.span),
    span: node.span,
    first: extractNum(input, childByName(node, "num", "first")),
    num: childrenByName(node, "num").map((child) => extractNum(input, child)),
  };
}
function extractMain(input: string, node: RuleTree): DL2Main {
  return {
    type: "Main",
    text: textForSpan(input, node.span),
    span: node.span,
    declaration: childrenByName(node, "declaration").map((child) =>
      extractDeclaration(input, child)
    ),
  };
}
function extractNegation(input: string, node: RuleTree): DL2Negation {
  return {
    type: "Negation",
    text: textForSpan(input, node.span),
    span: node.span,
    record: extractRecord(input, childByName(node, "record", null)),
  };
}
function extractNested(input: string, node: RuleTree): DL2Nested {
  return {
    type: "Nested",
    text: textForSpan(input, node.span),
    span: node.span,
    qualifier: extractQualifier(input, childByName(node, "qualifier", null)),
    nestedAttr: childrenByName(node, "nestedAttr").map((child) =>
      extractNestedAttr(input, child)
    ),
    commaSpace: childrenByName(node, "commaSpace").map((child) =>
      extractCommaSpace(input, child)
    ),
  };
}
function extractNestedAttr(input: string, node: RuleTree): DL2NestedAttr {
  const child = node.children[0];
  switch (child.name) {
    case "normalAttr": {
      return extractNormalAttr(input, child);
    }
    case "nested": {
      return extractNested(input, child);
    }
  }
}
function extractNormalAttr(input: string, node: RuleTree): DL2NormalAttr {
  return {
    type: "NormalAttr",
    text: textForSpan(input, node.span),
    span: node.span,
    ident: extractIdent(input, childByName(node, "ident", null)),
    term: extractTerm(input, childByName(node, "term", null)),
  };
}
function extractNum(input: string, node: RuleTree): DL2Num {
  return {
    type: "Num",
    text: textForSpan(input, node.span),
    span: node.span,
  };
}
function extractOutRef(input: string, node: RuleTree): DL2OutRef {
  return {
    type: "OutRef",
    text: textForSpan(input, node.span),
    span: node.span,
    outRefKW: extractOutRefKW(input, childByName(node, "outRefKW", null)),
    table: extractQualifier(input, childByName(node, "qualifier", "table")),
    col: extractIdent(input, childByName(node, "ident", "col")),
  };
}
function extractOutRefKW(input: string, node: RuleTree): DL2OutRefKW {
  return {
    type: "OutRefKW",
    text: textForSpan(input, node.span),
    span: node.span,
  };
}
function extractPath(input: string, node: RuleTree): DL2Path {
  return {
    type: "Path",
    text: textForSpan(input, node.span),
    span: node.span,
    pathSegment: childrenByName(node, "pathSegment").map((child) =>
      extractPathSegment(input, child)
    ),
  };
}
function extractPathSegment(input: string, node: RuleTree): DL2PathSegment {
  return {
    type: "PathSegment",
    text: textForSpan(input, node.span),
    span: node.span,
  };
}
function extractPlaceholder(input: string, node: RuleTree): DL2Placeholder {
  return {
    type: "Placeholder",
    text: textForSpan(input, node.span),
    span: node.span,
  };
}
function extractQualifier(input: string, node: RuleTree): DL2Qualifier {
  return {
    type: "Qualifier",
    text: textForSpan(input, node.span),
    span: node.span,
    ident: childrenByName(node, "ident").map((child) =>
      extractIdent(input, child)
    ),
  };
}
function extractQuery(input: string, node: RuleTree): DL2Query {
  return {
    type: "Query",
    text: textForSpan(input, node.span),
    span: node.span,
    record: extractRecord(input, childByName(node, "record", null)),
  };
}
function extractRecord(input: string, node: RuleTree): DL2Record {
  return {
    type: "Record",
    text: textForSpan(input, node.span),
    span: node.span,
    qualifier: extractQualifier(input, childByName(node, "qualifier", null)),
    recordAttrs: extractRecordAttrs(
      input,
      childByName(node, "recordAttrs", null)
    ),
  };
}
function extractRecordAttrs(input: string, node: RuleTree): DL2RecordAttrs {
  return {
    type: "RecordAttrs",
    text: textForSpan(input, node.span),
    span: node.span,
    recordKeyValue: childrenByName(node, "recordKeyValue").map((child) =>
      extractRecordKeyValue(input, child)
    ),
    placeholder: childrenByName(node, "placeholder").map((child) =>
      extractPlaceholder(input, child)
    ),
    commaSpace: childrenByName(node, "commaSpace").map((child) =>
      extractCommaSpace(input, child)
    ),
  };
}
function extractRecordKeyValue(
  input: string,
  node: RuleTree
): DL2RecordKeyValue {
  return {
    type: "RecordKeyValue",
    text: textForSpan(input, node.span),
    span: node.span,
    ident: extractIdent(input, childByName(node, "ident", null)),
    term: extractTerm(input, childByName(node, "term", null)),
  };
}
function extractRefSpec(input: string, node: RuleTree): DL2RefSpec {
  return {
    type: "RefSpec",
    text: textForSpan(input, node.span),
    span: node.span,
    outRef: childByName(node, "outRef", null)
      ? extractOutRef(input, childByName(node, "outRef", null))
      : null,
    inRef: childByName(node, "inRef", null)
      ? extractInRef(input, childByName(node, "inRef", null))
      : null,
  };
}
function extractRule(input: string, node: RuleTree): DL2Rule {
  return {
    type: "Rule",
    text: textForSpan(input, node.span),
    span: node.span,
    defKW: extractDefKW(input, childByName(node, "defKW", null)),
    record: extractRecord(input, childByName(node, "record", null)),
    disjunct: childrenByName(node, "disjunct").map((child) =>
      extractDisjunct(input, child)
    ),
  };
}
function extractSpaces(input: string, node: RuleTree): DL2Spaces {
  return {
    type: "Spaces",
    text: textForSpan(input, node.span),
    span: node.span,
  };
}
function extractString(input: string, node: RuleTree): DL2String {
  return {
    type: "String",
    text: textForSpan(input, node.span),
    span: node.span,
    stringChar: childrenByName(node, "stringChar").map((child) =>
      extractStringChar(input, child)
    ),
  };
}
function extractStringChar(input: string, node: RuleTree): DL2StringChar {
  return {
    type: "StringChar",
    text: textForSpan(input, node.span),
    span: node.span,
  };
}
function extractTableAttr(input: string, node: RuleTree): DL2TableAttr {
  return {
    type: "TableAttr",
    text: textForSpan(input, node.span),
    span: node.span,
    ident: extractIdent(input, childByName(node, "ident", null)),
    refSpec: childByName(node, "refSpec", null)
      ? extractRefSpec(input, childByName(node, "refSpec", null))
      : null,
  };
}
function extractTableDecl(input: string, node: RuleTree): DL2TableDecl {
  return {
    type: "TableDecl",
    text: textForSpan(input, node.span),
    span: node.span,
    tableKW: extractTableKW(input, childByName(node, "tableKW", null)),
    name: extractQualifier(input, childByName(node, "qualifier", "name")),
    tableAttr: childrenByName(node, "tableAttr").map((child) =>
      extractTableAttr(input, child)
    ),
    commaSpace: childrenByName(node, "commaSpace").map((child) =>
      extractCommaSpace(input, child)
    ),
  };
}
function extractTableKW(input: string, node: RuleTree): DL2TableKW {
  return {
    type: "TableKW",
    text: textForSpan(input, node.span),
    span: node.span,
  };
}
function extractTerm(input: string, node: RuleTree): DL2Term {
  const child = node.children[0];
  switch (child.name) {
    case "record": {
      return extractRecord(input, child);
    }
    case "int": {
      return extractInt(input, child);
    }
    case "var": {
      return extractVar(input, child);
    }
    case "string": {
      return extractString(input, child);
    }
    case "bool": {
      return extractBool(input, child);
    }
    case "array": {
      return extractArray(input, child);
    }
    case "dict": {
      return extractDict(input, child);
    }
    case "placeholder": {
      return extractPlaceholder(input, child);
    }
  }
}
function extractVar(input: string, node: RuleTree): DL2Var {
  return {
    type: "Var",
    text: textForSpan(input, node.span),
    span: node.span,
    alphaNum: childrenByName(node, "alphaNum").map((child) =>
      extractAlphaNum(input, child)
    ),
  };
}
export const GRAMMAR: Grammar = {
  main: {
    type: "Sequence",
    items: [
      {
        type: "Ref",
        captureName: null,
        rule: "ws",
      },
      {
        type: "RepSep",
        rep: {
          type: "Ref",
          captureName: null,
          rule: "declaration",
        },
        sep: {
          type: "Ref",
          captureName: null,
          rule: "ws",
        },
      },
      {
        type: "Ref",
        captureName: null,
        rule: "ws",
      },
    ],
  },
  declaration: {
    type: "Choice",
    choices: [
      {
        type: "Ref",
        captureName: null,
        rule: "rule",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "tableDecl",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "import",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "fact",
      },
    ],
  },
  comment: {
    type: "Sequence",
    items: [
      {
        type: "Text",
        value: "#",
      },
      {
        type: "RepSep",
        rep: {
          type: "Ref",
          captureName: null,
          rule: "commentChar",
        },
        sep: {
          type: "Text",
          value: "",
        },
      },
    ],
  },
  import: {
    type: "Sequence",
    items: [
      {
        type: "Ref",
        captureName: null,
        rule: "importKW",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "ws",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "path",
      },
    ],
  },
  tableDecl: {
    type: "Sequence",
    items: [
      {
        type: "Ref",
        captureName: null,
        rule: "tableKW",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "ws",
      },
      {
        type: "Ref",
        captureName: "name",
        rule: "qualifier",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "ws",
      },
      {
        type: "Text",
        value: "{",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "ws",
      },
      {
        type: "RepSep",
        rep: {
          type: "Ref",
          captureName: null,
          rule: "tableAttr",
        },
        sep: {
          type: "Ref",
          captureName: null,
          rule: "commaSpace",
        },
      },
      {
        type: "Ref",
        captureName: null,
        rule: "ws",
      },
      {
        type: "Text",
        value: "}",
      },
    ],
  },
  tableAttr: {
    type: "Sequence",
    items: [
      {
        type: "Ref",
        captureName: null,
        rule: "ident",
      },
      {
        type: "Choice",
        choices: [
          {
            type: "Ref",
            captureName: null,
            rule: "refSpec",
          },
          {
            type: "Text",
            value: "",
          },
        ],
      },
    ],
  },
  refSpec: {
    type: "Sequence",
    items: [
      {
        type: "Text",
        value: ":",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "ws",
      },
      {
        type: "Choice",
        choices: [
          {
            type: "Ref",
            captureName: null,
            rule: "outRef",
          },
          {
            type: "Ref",
            captureName: null,
            rule: "inRef",
          },
        ],
      },
    ],
  },
  outRef: {
    type: "Sequence",
    items: [
      {
        type: "Ref",
        captureName: null,
        rule: "outRefKW",
      },
      {
        type: "Text",
        value: "(",
      },
      {
        type: "Ref",
        captureName: "table",
        rule: "qualifier",
      },
      {
        type: "Text",
        value: ":",
      },
      {
        type: "Ref",
        captureName: "col",
        rule: "ident",
      },
      {
        type: "Text",
        value: ")",
      },
    ],
  },
  inRef: {
    type: "Sequence",
    items: [
      {
        type: "Ref",
        captureName: null,
        rule: "inRefKW",
      },
      {
        type: "Text",
        value: "(",
      },
      {
        type: "Ref",
        captureName: "table",
        rule: "qualifier",
      },
      {
        type: "Text",
        value: ":",
      },
      {
        type: "Ref",
        captureName: "col",
        rule: "ident",
      },
      {
        type: "Text",
        value: ")",
      },
    ],
  },
  inRefKW: {
    type: "Text",
    value: "inRef",
  },
  outRefKW: {
    type: "Text",
    value: "outRef",
  },
  query: {
    type: "Sequence",
    items: [
      {
        type: "Ref",
        captureName: null,
        rule: "record",
      },
      {
        type: "Text",
        value: "?",
      },
    ],
  },
  fact: {
    type: "Sequence",
    items: [
      {
        type: "Ref",
        captureName: null,
        rule: "record",
      },
      {
        type: "Text",
        value: ".",
      },
    ],
  },
  deleteFact: {
    type: "Sequence",
    items: [
      {
        type: "Text",
        value: "-",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "record",
      },
      {
        type: "Text",
        value: ".",
      },
    ],
  },
  rule: {
    type: "Sequence",
    items: [
      {
        type: "Ref",
        captureName: null,
        rule: "defKW",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "ws",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "record",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "ws",
      },
      {
        type: "Text",
        value: "{",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "ws",
      },
      {
        type: "RepSep",
        rep: {
          type: "Ref",
          captureName: null,
          rule: "disjunct",
        },
        sep: {
          type: "Sequence",
          items: [
            {
              type: "Ref",
              captureName: null,
              rule: "ws",
            },
            {
              type: "Text",
              value: "|",
            },
            {
              type: "Ref",
              captureName: null,
              rule: "ws",
            },
          ],
        },
      },
      {
        type: "Ref",
        captureName: null,
        rule: "ws",
      },
      {
        type: "Text",
        value: "}",
      },
    ],
  },
  disjunct: {
    type: "RepSep",
    rep: {
      type: "Ref",
      captureName: null,
      rule: "conjunct",
    },
    sep: {
      type: "Sequence",
      items: [
        {
          type: "Ref",
          captureName: null,
          rule: "ws",
        },
        {
          type: "Text",
          value: "&",
        },
        {
          type: "Ref",
          captureName: null,
          rule: "ws",
        },
      ],
    },
  },
  conjunct: {
    type: "Choice",
    choices: [
      {
        type: "Ref",
        captureName: null,
        rule: "nested",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "record",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "comparison",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "arithmetic",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "negation",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "aggregation",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "placeholder",
      },
    ],
  },
  negation: {
    type: "Sequence",
    items: [
      {
        type: "Text",
        value: "!",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "record",
      },
    ],
  },
  aggregation: {
    type: "Sequence",
    items: [
      {
        type: "Ref",
        captureName: "aggregation",
        rule: "ident",
      },
      {
        type: "Text",
        value: "[",
      },
      {
        type: "RepSep",
        rep: {
          type: "Ref",
          captureName: null,
          rule: "var",
        },
        sep: {
          type: "Ref",
          captureName: null,
          rule: "commaSpace",
        },
      },
      {
        type: "Text",
        value: ":",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "ws",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "record",
      },
      {
        type: "Text",
        value: "]",
      },
    ],
  },
  comparison: {
    type: "Sequence",
    items: [
      {
        type: "Ref",
        captureName: "left",
        rule: "term",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "ws",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "comparisonOp",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "ws",
      },
      {
        type: "Ref",
        captureName: "right",
        rule: "term",
      },
    ],
  },
  comparisonOp: {
    type: "Choice",
    choices: [
      {
        type: "Text",
        value: "<=",
      },
      {
        type: "Text",
        value: ">=",
      },
      {
        type: "Text",
        value: ">",
      },
      {
        type: "Text",
        value: "<",
      },
      {
        type: "Text",
        value: "=",
      },
      {
        type: "Text",
        value: "!=",
      },
    ],
  },
  nested: {
    type: "Sequence",
    items: [
      {
        type: "Ref",
        captureName: null,
        rule: "qualifier",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "ws",
      },
      {
        type: "Text",
        value: "{",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "ws",
      },
      {
        type: "RepSep",
        rep: {
          type: "Ref",
          captureName: null,
          rule: "nestedAttr",
        },
        sep: {
          type: "Ref",
          captureName: null,
          rule: "commaSpace",
        },
      },
      {
        type: "Ref",
        captureName: null,
        rule: "ws",
      },
      {
        type: "Text",
        value: "}",
      },
    ],
  },
  nestedAttr: {
    type: "Choice",
    choices: [
      {
        type: "Ref",
        captureName: null,
        rule: "normalAttr",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "nested",
      },
    ],
  },
  normalAttr: {
    type: "Sequence",
    items: [
      {
        type: "Ref",
        captureName: null,
        rule: "ident",
      },
      {
        type: "Text",
        value: ":",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "ws",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "term",
      },
    ],
  },
  arithmetic: {
    type: "Choice",
    choices: [
      {
        type: "Ref",
        captureName: null,
        rule: "assignmentOnLeft",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "assignmentOnRight",
      },
    ],
  },
  assignmentOnRight: {
    type: "Sequence",
    items: [
      {
        type: "Ref",
        captureName: "left",
        rule: "term",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "ws",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "arithmeticOp",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "ws",
      },
      {
        type: "Ref",
        captureName: "right",
        rule: "term",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "ws",
      },
      {
        type: "Text",
        value: "=",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "ws",
      },
      {
        type: "Ref",
        captureName: "result",
        rule: "term",
      },
    ],
  },
  assignmentOnLeft: {
    type: "Sequence",
    items: [
      {
        type: "Ref",
        captureName: "result",
        rule: "term",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "ws",
      },
      {
        type: "Text",
        value: "=",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "ws",
      },
      {
        type: "Ref",
        captureName: "left",
        rule: "term",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "ws",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "arithmeticOp",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "ws",
      },
      {
        type: "Ref",
        captureName: "right",
        rule: "term",
      },
    ],
  },
  arithmeticOp: {
    type: "Choice",
    choices: [
      {
        type: "Text",
        value: "+",
      },
      {
        type: "Text",
        value: "*",
      },
      {
        type: "Text",
        value: "-",
      },
    ],
  },
  term: {
    type: "Choice",
    choices: [
      {
        type: "Ref",
        captureName: null,
        rule: "record",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "int",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "var",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "string",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "bool",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "array",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "dict",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "placeholder",
      },
    ],
  },
  var: {
    type: "Sequence",
    items: [
      {
        type: "Char",
        rule: {
          type: "Range",
          from: "A",
          to: "Z",
        },
      },
      {
        type: "RepSep",
        rep: {
          type: "Choice",
          choices: [
            {
              type: "Char",
              rule: {
                type: "Range",
                from: "A",
                to: "Z",
              },
            },
            {
              type: "Ref",
              captureName: null,
              rule: "alphaNum",
            },
          ],
        },
        sep: {
          type: "Text",
          value: "",
        },
      },
    ],
  },
  record: {
    type: "Sequence",
    items: [
      {
        type: "Ref",
        captureName: null,
        rule: "qualifier",
      },
      {
        type: "Text",
        value: "{",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "ws",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "recordAttrs",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "ws",
      },
      {
        type: "Text",
        value: "}",
      },
    ],
  },
  dict: {
    type: "Sequence",
    items: [
      {
        type: "Text",
        value: "{",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "ws",
      },
      {
        type: "RepSep",
        rep: {
          type: "Ref",
          captureName: null,
          rule: "dictKeyValue",
        },
        sep: {
          type: "Ref",
          captureName: null,
          rule: "commaSpace",
        },
      },
      {
        type: "Ref",
        captureName: null,
        rule: "ws",
      },
      {
        type: "Text",
        value: "}",
      },
    ],
  },
  dictKeyValue: {
    type: "Sequence",
    items: [
      {
        type: "Ref",
        captureName: "key",
        rule: "string",
      },
      {
        type: "Text",
        value: ":",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "ws",
      },
      {
        type: "Ref",
        captureName: "value",
        rule: "term",
      },
    ],
  },
  recordAttrs: {
    type: "RepSep",
    rep: {
      type: "Choice",
      choices: [
        {
          type: "Ref",
          captureName: null,
          rule: "recordKeyValue",
        },
        {
          type: "Ref",
          captureName: null,
          rule: "placeholder",
        },
      ],
    },
    sep: {
      type: "Ref",
      captureName: null,
      rule: "commaSpace",
    },
  },
  recordKeyValue: {
    type: "Sequence",
    items: [
      {
        type: "Ref",
        captureName: null,
        rule: "ident",
      },
      {
        type: "Text",
        value: ":",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "ws",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "term",
      },
    ],
  },
  int: {
    type: "Sequence",
    items: [
      {
        type: "Choice",
        choices: [
          {
            type: "Text",
            value: "-",
          },
          {
            type: "Text",
            value: "",
          },
        ],
      },
      {
        type: "Ref",
        captureName: "first",
        rule: "num",
      },
      {
        type: "RepSep",
        rep: {
          type: "Ref",
          captureName: null,
          rule: "num",
        },
        sep: {
          type: "Text",
          value: "",
        },
      },
    ],
  },
  bool: {
    type: "Choice",
    choices: [
      {
        type: "Text",
        value: "true",
      },
      {
        type: "Text",
        value: "false",
      },
    ],
  },
  array: {
    type: "Sequence",
    items: [
      {
        type: "Text",
        value: "[",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "ws",
      },
      {
        type: "RepSep",
        rep: {
          type: "Ref",
          captureName: null,
          rule: "term",
        },
        sep: {
          type: "Ref",
          captureName: null,
          rule: "commaSpace",
        },
      },
      {
        type: "Ref",
        captureName: null,
        rule: "ws",
      },
      {
        type: "Text",
        value: "]",
      },
    ],
  },
  tableKW: {
    type: "Text",
    value: "table",
  },
  importKW: {
    type: "Text",
    value: "import",
  },
  defKW: {
    type: "Text",
    value: "def",
  },
  qualifier: {
    type: "RepSep",
    rep: {
      type: "Ref",
      captureName: null,
      rule: "ident",
    },
    sep: {
      type: "Text",
      value: ".",
    },
  },
  ident: {
    type: "Sequence",
    items: [
      {
        type: "Ref",
        captureName: null,
        rule: "alpha",
      },
      {
        type: "RepSep",
        rep: {
          type: "Ref",
          captureName: null,
          rule: "alphaNum",
        },
        sep: {
          type: "Text",
          value: "",
        },
      },
    ],
  },
  string: {
    type: "Sequence",
    items: [
      {
        type: "Text",
        value: '"',
      },
      {
        type: "RepSep",
        rep: {
          type: "Ref",
          captureName: null,
          rule: "stringChar",
        },
        sep: {
          type: "Text",
          value: "",
        },
      },
      {
        type: "Text",
        value: '"',
      },
    ],
  },
  stringChar: {
    type: "Choice",
    choices: [
      {
        type: "Char",
        rule: {
          type: "Not",
          rule: {
            type: "Literal",
            value: '"',
          },
        },
      },
      {
        type: "Sequence",
        items: [
          {
            type: "Char",
            rule: {
              type: "Literal",
              value: "\\",
            },
          },
          {
            type: "Char",
            rule: {
              type: "Literal",
              value: '"',
            },
          },
        ],
      },
    ],
  },
  alpha: {
    type: "Choice",
    choices: [
      {
        type: "Char",
        rule: {
          type: "Range",
          from: "a",
          to: "z",
        },
      },
      {
        type: "Char",
        rule: {
          type: "Range",
          from: "A",
          to: "Z",
        },
      },
      {
        type: "Text",
        value: "_",
      },
    ],
  },
  num: {
    type: "Char",
    rule: {
      type: "Range",
      from: "0",
      to: "9",
    },
  },
  alphaNum: {
    type: "Choice",
    choices: [
      {
        type: "Ref",
        captureName: null,
        rule: "alpha",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "num",
      },
    ],
  },
  ws: {
    type: "RepSep",
    rep: {
      type: "Sequence",
      items: [
        {
          type: "Ref",
          captureName: null,
          rule: "spaces",
        },
        {
          type: "Choice",
          choices: [
            {
              type: "Ref",
              captureName: null,
              rule: "comment",
            },
            {
              type: "Text",
              value: "",
            },
          ],
        },
      ],
    },
    sep: {
      type: "Text",
      value: "\n",
    },
  },
  spaces: {
    type: "RepSep",
    rep: {
      type: "Text",
      value: " ",
    },
    sep: {
      type: "Text",
      value: "",
    },
  },
  placeholder: {
    type: "Text",
    value: "???",
  },
  commaSpace: {
    type: "Sequence",
    items: [
      {
        type: "Text",
        value: ",",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "ws",
      },
    ],
  },
  path: {
    type: "Sequence",
    items: [
      {
        type: "Text",
        value: '"',
      },
      {
        type: "RepSep",
        rep: {
          type: "Ref",
          captureName: null,
          rule: "pathSegment",
        },
        sep: {
          type: "Text",
          value: "/",
        },
      },
      {
        type: "Text",
        value: '"',
      },
    ],
  },
  pathSegment: {
    type: "RepSep",
    rep: {
      type: "Choice",
      choices: [
        {
          type: "Char",
          rule: {
            type: "Range",
            from: "a",
            to: "z",
          },
        },
        {
          type: "Char",
          rule: {
            type: "Range",
            from: "A",
            to: "Z",
          },
        },
        {
          type: "Char",
          rule: {
            type: "Range",
            from: "0",
            to: "9",
          },
        },
        {
          type: "Char",
          rule: {
            type: "Literal",
            value: "_",
          },
        },
        {
          type: "Char",
          rule: {
            type: "Literal",
            value: "-",
          },
        },
        {
          type: "Char",
          rule: {
            type: "Literal",
            value: ".",
          },
        },
      ],
    },
    sep: {
      type: "Text",
      value: "",
    },
  },
  commentChar: {
    type: "Char",
    rule: {
      type: "Not",
      rule: {
        type: "Literal",
        value: "\n",
      },
    },
  },
};
