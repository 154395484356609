# nodes

viz.scopeGraph.node{id: ID, label: L} :-
  scope.Scope{id: ID, label: L}.

# edges

viz.scopeGraph.edge{from: F, to: T, label: L} :-
  viz.scopeGraph.parentEdge{from: F, to: T, label: L} |
  viz.scopeGraph.usageEdge{from: F, to: T, label: L}.
viz.scopeGraph.parentEdge{from: F, to: T, label: "child"} :-
  scope.Parent{parentID: F, childID: T}.
viz.scopeGraph.usageEdge{from: F, to: T, label: "usage"} :-
  scope.Usage{definitionScopeID: T, usageScopeID: F, name: N}.

internal.visualization{
  name: "Scope Graph",
  spec: graphviz{
    nodes: viz.scopeGraph.node{id: ID, label: Label},
    edges: viz.scopeGraph.edge{from: From, to: To, label: Label}
  }
}.

# TODO: tree viz as well?
