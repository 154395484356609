// generated by parserlib; do not edit.
import {
  textForSpan,
  childByName,
  childrenByName,
  RuleTree,
  extractRuleTree,
} from "../../parserlib/ruleTree";
import { Span, Grammar, ParseError } from "../../parserlib/types";
import * as parserlib from "../../parserlib/parser";
export type DLAggregation = {
  type: "Aggregation";
  text: string;
  span: Span;
  aggregation: DLIdent;
  var: DLVar[];
  commaSpace: DLCommaSpace[];
  record: DLRecord;
};
export type DLAlpha = {
  type: "Alpha";
  text: string;
  span: Span;
};
export type DLAlphaNum = DLAlpha | DLNum;
export type DLArithmetic = DLAssignmentOnLeft | DLAssignmentOnRight;
export type DLArithmeticOp = {
  type: "ArithmeticOp";
  text: string;
  span: Span;
};
export type DLArray = {
  type: "Array";
  text: string;
  span: Span;
  term: DLTerm[];
  commaSpace: DLCommaSpace[];
};
export type DLAssignmentOnLeft = {
  type: "AssignmentOnLeft";
  text: string;
  span: Span;
  result: DLTerm;
  left: DLTerm;
  arithmeticOp: DLArithmeticOp;
  right: DLTerm;
};
export type DLAssignmentOnRight = {
  type: "AssignmentOnRight";
  text: string;
  span: Span;
  left: DLTerm;
  arithmeticOp: DLArithmeticOp;
  right: DLTerm;
  result: DLTerm;
};
export type DLBool = {
  type: "Bool";
  text: string;
  span: Span;
};
export type DLCommaSpace = {
  type: "CommaSpace";
  text: string;
  span: Span;
};
export type DLComment = {
  type: "Comment";
  text: string;
  span: Span;
  commentChar: DLCommentChar[];
};
export type DLCommentChar = {
  type: "CommentChar";
  text: string;
  span: Span;
};
export type DLComparison = {
  type: "Comparison";
  text: string;
  span: Span;
  left: DLTerm;
  comparisonOp: DLComparisonOp;
  right: DLTerm;
};
export type DLComparisonOp = {
  type: "ComparisonOp";
  text: string;
  span: Span;
};
export type DLConjunct =
  | DLRecord
  | DLComparison
  | DLArithmetic
  | DLNegation
  | DLAggregation
  | DLPlaceholder;
export type DLDeleteFact = {
  type: "DeleteFact";
  text: string;
  span: Span;
  record: DLRecord;
};
export type DLDict = {
  type: "Dict";
  text: string;
  span: Span;
  dictKeyValue: DLDictKeyValue[];
  commaSpace: DLCommaSpace[];
};
export type DLDictKeyValue = {
  type: "DictKeyValue";
  text: string;
  span: Span;
  key: DLString;
  value: DLTerm;
};
export type DLDisjunct = {
  type: "Disjunct";
  text: string;
  span: Span;
  conjunct: DLConjunct[];
};
export type DLFact = {
  type: "Fact";
  text: string;
  span: Span;
  record: DLRecord;
};
export type DLIdent = {
  type: "Ident";
  text: string;
  span: Span;
  alpha: DLAlpha;
  alphaNum: DLAlphaNum[];
};
export type DLInt = {
  type: "Int";
  text: string;
  span: Span;
  first: DLNum;
  num: DLNum[];
};
export type DLLoadKW = {
  type: "LoadKW";
  text: string;
  span: Span;
};
export type DLLoadStmt = {
  type: "LoadStmt";
  text: string;
  span: Span;
  loadKW: DLLoadKW;
  path: DLPath;
};
export type DLMain = {
  type: "Main";
  text: string;
  span: Span;
  statement: DLStatement[];
};
export type DLNegation = {
  type: "Negation";
  text: string;
  span: Span;
  record: DLRecord;
};
export type DLNum = {
  type: "Num";
  text: string;
  span: Span;
};
export type DLPath = {
  type: "Path";
  text: string;
  span: Span;
  pathSegment: DLPathSegment[];
};
export type DLPathSegment = {
  type: "PathSegment";
  text: string;
  span: Span;
};
export type DLPlaceholder = {
  type: "Placeholder";
  text: string;
  span: Span;
};
export type DLQuery = {
  type: "Query";
  text: string;
  span: Span;
  record: DLRecord;
};
export type DLRecord = {
  type: "Record";
  text: string;
  span: Span;
  ident: DLIdent;
  recordAttrs: DLRecordAttrs;
};
export type DLRecordAttrs = {
  type: "RecordAttrs";
  text: string;
  span: Span;
  recordKeyValue: DLRecordKeyValue[];
  placeholder: DLPlaceholder[];
  commaSpace: DLCommaSpace[];
};
export type DLRecordKeyValue = {
  type: "RecordKeyValue";
  text: string;
  span: Span;
  ident: DLIdent;
  term: DLTerm;
};
export type DLRule = {
  type: "Rule";
  text: string;
  span: Span;
  record: DLRecord;
  disjunct: DLDisjunct[];
};
export type DLSpaces = {
  type: "Spaces";
  text: string;
  span: Span;
};
export type DLStatement =
  | DLRule
  | DLFact
  | DLQuery
  | DLDeleteFact
  | DLTableDecl
  | DLLoadStmt;
export type DLString = {
  type: "String";
  text: string;
  span: Span;
  stringChar: DLStringChar[];
};
export type DLStringChar = {
  type: "StringChar";
  text: string;
  span: Span;
};
export type DLTableDecl = {
  type: "TableDecl";
  text: string;
  span: Span;
  tableKW: DLTableKW;
  name: DLIdent;
};
export type DLTableKW = {
  type: "TableKW";
  text: string;
  span: Span;
};
export type DLTerm =
  | DLRecord
  | DLInt
  | DLVar
  | DLString
  | DLBool
  | DLArray
  | DLDict
  | DLPlaceholder;
export type DLVar = {
  type: "Var";
  text: string;
  span: Span;
  alphaNum: DLAlphaNum[];
};
export type DLWs = {
  type: "Ws";
  text: string;
  span: Span;
  spaces: DLSpaces[];
  comment: DLComment[];
};
export function parseAggregation(input: string): [DLAggregation, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "aggregation", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractAggregation(input, ruleTree);
  return [extracted, errors];
}
export function parseAlpha(input: string): [DLAlpha, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "alpha", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractAlpha(input, ruleTree);
  return [extracted, errors];
}
export function parseAlphaNum(input: string): [DLAlphaNum, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "alphaNum", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractAlphaNum(input, ruleTree);
  return [extracted, errors];
}
export function parseArithmetic(input: string): [DLArithmetic, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "arithmetic", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractArithmetic(input, ruleTree);
  return [extracted, errors];
}
export function parseArithmeticOp(
  input: string
): [DLArithmeticOp, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "arithmeticOp", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractArithmeticOp(input, ruleTree);
  return [extracted, errors];
}
export function parseArray(input: string): [DLArray, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "array", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractArray(input, ruleTree);
  return [extracted, errors];
}
export function parseAssignmentOnLeft(
  input: string
): [DLAssignmentOnLeft, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "assignmentOnLeft", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractAssignmentOnLeft(input, ruleTree);
  return [extracted, errors];
}
export function parseAssignmentOnRight(
  input: string
): [DLAssignmentOnRight, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "assignmentOnRight", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractAssignmentOnRight(input, ruleTree);
  return [extracted, errors];
}
export function parseBool(input: string): [DLBool, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "bool", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractBool(input, ruleTree);
  return [extracted, errors];
}
export function parseCommaSpace(input: string): [DLCommaSpace, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "commaSpace", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractCommaSpace(input, ruleTree);
  return [extracted, errors];
}
export function parseComment(input: string): [DLComment, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "comment", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractComment(input, ruleTree);
  return [extracted, errors];
}
export function parseCommentChar(input: string): [DLCommentChar, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "commentChar", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractCommentChar(input, ruleTree);
  return [extracted, errors];
}
export function parseComparison(input: string): [DLComparison, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "comparison", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractComparison(input, ruleTree);
  return [extracted, errors];
}
export function parseComparisonOp(
  input: string
): [DLComparisonOp, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "comparisonOp", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractComparisonOp(input, ruleTree);
  return [extracted, errors];
}
export function parseConjunct(input: string): [DLConjunct, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "conjunct", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractConjunct(input, ruleTree);
  return [extracted, errors];
}
export function parseDeleteFact(input: string): [DLDeleteFact, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "deleteFact", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractDeleteFact(input, ruleTree);
  return [extracted, errors];
}
export function parseDict(input: string): [DLDict, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "dict", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractDict(input, ruleTree);
  return [extracted, errors];
}
export function parseDictKeyValue(
  input: string
): [DLDictKeyValue, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "dictKeyValue", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractDictKeyValue(input, ruleTree);
  return [extracted, errors];
}
export function parseDisjunct(input: string): [DLDisjunct, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "disjunct", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractDisjunct(input, ruleTree);
  return [extracted, errors];
}
export function parseFact(input: string): [DLFact, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "fact", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractFact(input, ruleTree);
  return [extracted, errors];
}
export function parseIdent(input: string): [DLIdent, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "ident", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractIdent(input, ruleTree);
  return [extracted, errors];
}
export function parseInt(input: string): [DLInt, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "int", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractInt(input, ruleTree);
  return [extracted, errors];
}
export function parseLoadKW(input: string): [DLLoadKW, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "loadKW", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractLoadKW(input, ruleTree);
  return [extracted, errors];
}
export function parseLoadStmt(input: string): [DLLoadStmt, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "loadStmt", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractLoadStmt(input, ruleTree);
  return [extracted, errors];
}
export function parseMain(input: string): [DLMain, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "main", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractMain(input, ruleTree);
  return [extracted, errors];
}
export function parseNegation(input: string): [DLNegation, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "negation", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractNegation(input, ruleTree);
  return [extracted, errors];
}
export function parseNum(input: string): [DLNum, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "num", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractNum(input, ruleTree);
  return [extracted, errors];
}
export function parsePath(input: string): [DLPath, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "path", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractPath(input, ruleTree);
  return [extracted, errors];
}
export function parsePathSegment(input: string): [DLPathSegment, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "pathSegment", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractPathSegment(input, ruleTree);
  return [extracted, errors];
}
export function parsePlaceholder(input: string): [DLPlaceholder, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "placeholder", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractPlaceholder(input, ruleTree);
  return [extracted, errors];
}
export function parseQuery(input: string): [DLQuery, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "query", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractQuery(input, ruleTree);
  return [extracted, errors];
}
export function parseRecord(input: string): [DLRecord, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "record", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractRecord(input, ruleTree);
  return [extracted, errors];
}
export function parseRecordAttrs(input: string): [DLRecordAttrs, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "recordAttrs", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractRecordAttrs(input, ruleTree);
  return [extracted, errors];
}
export function parseRecordKeyValue(
  input: string
): [DLRecordKeyValue, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "recordKeyValue", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractRecordKeyValue(input, ruleTree);
  return [extracted, errors];
}
export function parseRule(input: string): [DLRule, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "rule", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractRule(input, ruleTree);
  return [extracted, errors];
}
export function parseSpaces(input: string): [DLSpaces, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "spaces", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractSpaces(input, ruleTree);
  return [extracted, errors];
}
export function parseStatement(input: string): [DLStatement, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "statement", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractStatement(input, ruleTree);
  return [extracted, errors];
}
export function parseString(input: string): [DLString, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "string", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractString(input, ruleTree);
  return [extracted, errors];
}
export function parseStringChar(input: string): [DLStringChar, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "stringChar", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractStringChar(input, ruleTree);
  return [extracted, errors];
}
export function parseTableDecl(input: string): [DLTableDecl, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "tableDecl", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractTableDecl(input, ruleTree);
  return [extracted, errors];
}
export function parseTableKW(input: string): [DLTableKW, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "tableKW", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractTableKW(input, ruleTree);
  return [extracted, errors];
}
export function parseTerm(input: string): [DLTerm, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "term", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractTerm(input, ruleTree);
  return [extracted, errors];
}
export function parseVar(input: string): [DLVar, ParseError[]] {
  const traceTree = parserlib.parse(GRAMMAR, "var", input);
  const [ruleTree, errors] = extractRuleTree(traceTree);
  const extracted = extractVar(input, ruleTree);
  return [extracted, errors];
}
function extractAggregation(input: string, node: RuleTree): DLAggregation {
  return {
    type: "Aggregation",
    text: textForSpan(input, node.span),
    span: node.span,
    aggregation: extractIdent(input, childByName(node, "ident", "aggregation")),
    var: childrenByName(node, "var").map((child) => extractVar(input, child)),
    commaSpace: childrenByName(node, "commaSpace").map((child) =>
      extractCommaSpace(input, child)
    ),
    record: extractRecord(input, childByName(node, "record", null)),
  };
}
function extractAlpha(input: string, node: RuleTree): DLAlpha {
  return {
    type: "Alpha",
    text: textForSpan(input, node.span),
    span: node.span,
  };
}
function extractAlphaNum(input: string, node: RuleTree): DLAlphaNum {
  const child = node.children[0];
  switch (child.name) {
    case "alpha": {
      return extractAlpha(input, child);
    }
    case "num": {
      return extractNum(input, child);
    }
  }
}
function extractArithmetic(input: string, node: RuleTree): DLArithmetic {
  const child = node.children[0];
  switch (child.name) {
    case "assignmentOnLeft": {
      return extractAssignmentOnLeft(input, child);
    }
    case "assignmentOnRight": {
      return extractAssignmentOnRight(input, child);
    }
  }
}
function extractArithmeticOp(input: string, node: RuleTree): DLArithmeticOp {
  return {
    type: "ArithmeticOp",
    text: textForSpan(input, node.span),
    span: node.span,
  };
}
function extractArray(input: string, node: RuleTree): DLArray {
  return {
    type: "Array",
    text: textForSpan(input, node.span),
    span: node.span,
    term: childrenByName(node, "term").map((child) =>
      extractTerm(input, child)
    ),
    commaSpace: childrenByName(node, "commaSpace").map((child) =>
      extractCommaSpace(input, child)
    ),
  };
}
function extractAssignmentOnLeft(
  input: string,
  node: RuleTree
): DLAssignmentOnLeft {
  return {
    type: "AssignmentOnLeft",
    text: textForSpan(input, node.span),
    span: node.span,
    result: extractTerm(input, childByName(node, "term", "result")),
    left: extractTerm(input, childByName(node, "term", "left")),
    arithmeticOp: extractArithmeticOp(
      input,
      childByName(node, "arithmeticOp", null)
    ),
    right: extractTerm(input, childByName(node, "term", "right")),
  };
}
function extractAssignmentOnRight(
  input: string,
  node: RuleTree
): DLAssignmentOnRight {
  return {
    type: "AssignmentOnRight",
    text: textForSpan(input, node.span),
    span: node.span,
    left: extractTerm(input, childByName(node, "term", "left")),
    arithmeticOp: extractArithmeticOp(
      input,
      childByName(node, "arithmeticOp", null)
    ),
    right: extractTerm(input, childByName(node, "term", "right")),
    result: extractTerm(input, childByName(node, "term", "result")),
  };
}
function extractBool(input: string, node: RuleTree): DLBool {
  return {
    type: "Bool",
    text: textForSpan(input, node.span),
    span: node.span,
  };
}
function extractCommaSpace(input: string, node: RuleTree): DLCommaSpace {
  return {
    type: "CommaSpace",
    text: textForSpan(input, node.span),
    span: node.span,
  };
}
function extractComment(input: string, node: RuleTree): DLComment {
  return {
    type: "Comment",
    text: textForSpan(input, node.span),
    span: node.span,
    commentChar: childrenByName(node, "commentChar").map((child) =>
      extractCommentChar(input, child)
    ),
  };
}
function extractCommentChar(input: string, node: RuleTree): DLCommentChar {
  return {
    type: "CommentChar",
    text: textForSpan(input, node.span),
    span: node.span,
  };
}
function extractComparison(input: string, node: RuleTree): DLComparison {
  return {
    type: "Comparison",
    text: textForSpan(input, node.span),
    span: node.span,
    left: extractTerm(input, childByName(node, "term", "left")),
    comparisonOp: extractComparisonOp(
      input,
      childByName(node, "comparisonOp", null)
    ),
    right: extractTerm(input, childByName(node, "term", "right")),
  };
}
function extractComparisonOp(input: string, node: RuleTree): DLComparisonOp {
  return {
    type: "ComparisonOp",
    text: textForSpan(input, node.span),
    span: node.span,
  };
}
function extractConjunct(input: string, node: RuleTree): DLConjunct {
  const child = node.children[0];
  switch (child.name) {
    case "record": {
      return extractRecord(input, child);
    }
    case "comparison": {
      return extractComparison(input, child);
    }
    case "arithmetic": {
      return extractArithmetic(input, child);
    }
    case "negation": {
      return extractNegation(input, child);
    }
    case "aggregation": {
      return extractAggregation(input, child);
    }
    case "placeholder": {
      return extractPlaceholder(input, child);
    }
  }
}
function extractDeleteFact(input: string, node: RuleTree): DLDeleteFact {
  return {
    type: "DeleteFact",
    text: textForSpan(input, node.span),
    span: node.span,
    record: extractRecord(input, childByName(node, "record", null)),
  };
}
function extractDict(input: string, node: RuleTree): DLDict {
  return {
    type: "Dict",
    text: textForSpan(input, node.span),
    span: node.span,
    dictKeyValue: childrenByName(node, "dictKeyValue").map((child) =>
      extractDictKeyValue(input, child)
    ),
    commaSpace: childrenByName(node, "commaSpace").map((child) =>
      extractCommaSpace(input, child)
    ),
  };
}
function extractDictKeyValue(input: string, node: RuleTree): DLDictKeyValue {
  return {
    type: "DictKeyValue",
    text: textForSpan(input, node.span),
    span: node.span,
    key: extractString(input, childByName(node, "string", "key")),
    value: extractTerm(input, childByName(node, "term", "value")),
  };
}
function extractDisjunct(input: string, node: RuleTree): DLDisjunct {
  return {
    type: "Disjunct",
    text: textForSpan(input, node.span),
    span: node.span,
    conjunct: childrenByName(node, "conjunct").map((child) =>
      extractConjunct(input, child)
    ),
  };
}
function extractFact(input: string, node: RuleTree): DLFact {
  return {
    type: "Fact",
    text: textForSpan(input, node.span),
    span: node.span,
    record: extractRecord(input, childByName(node, "record", null)),
  };
}
function extractIdent(input: string, node: RuleTree): DLIdent {
  return {
    type: "Ident",
    text: textForSpan(input, node.span),
    span: node.span,
    alpha: extractAlpha(input, childByName(node, "alpha", null)),
    alphaNum: childrenByName(node, "alphaNum").map((child) =>
      extractAlphaNum(input, child)
    ),
  };
}
function extractInt(input: string, node: RuleTree): DLInt {
  return {
    type: "Int",
    text: textForSpan(input, node.span),
    span: node.span,
    first: extractNum(input, childByName(node, "num", "first")),
    num: childrenByName(node, "num").map((child) => extractNum(input, child)),
  };
}
function extractLoadKW(input: string, node: RuleTree): DLLoadKW {
  return {
    type: "LoadKW",
    text: textForSpan(input, node.span),
    span: node.span,
  };
}
function extractLoadStmt(input: string, node: RuleTree): DLLoadStmt {
  return {
    type: "LoadStmt",
    text: textForSpan(input, node.span),
    span: node.span,
    loadKW: extractLoadKW(input, childByName(node, "loadKW", null)),
    path: extractPath(input, childByName(node, "path", null)),
  };
}
function extractMain(input: string, node: RuleTree): DLMain {
  return {
    type: "Main",
    text: textForSpan(input, node.span),
    span: node.span,
    statement: childrenByName(node, "statement").map((child) =>
      extractStatement(input, child)
    ),
  };
}
function extractNegation(input: string, node: RuleTree): DLNegation {
  return {
    type: "Negation",
    text: textForSpan(input, node.span),
    span: node.span,
    record: extractRecord(input, childByName(node, "record", null)),
  };
}
function extractNum(input: string, node: RuleTree): DLNum {
  return {
    type: "Num",
    text: textForSpan(input, node.span),
    span: node.span,
  };
}
function extractPath(input: string, node: RuleTree): DLPath {
  return {
    type: "Path",
    text: textForSpan(input, node.span),
    span: node.span,
    pathSegment: childrenByName(node, "pathSegment").map((child) =>
      extractPathSegment(input, child)
    ),
  };
}
function extractPathSegment(input: string, node: RuleTree): DLPathSegment {
  return {
    type: "PathSegment",
    text: textForSpan(input, node.span),
    span: node.span,
  };
}
function extractPlaceholder(input: string, node: RuleTree): DLPlaceholder {
  return {
    type: "Placeholder",
    text: textForSpan(input, node.span),
    span: node.span,
  };
}
function extractQuery(input: string, node: RuleTree): DLQuery {
  return {
    type: "Query",
    text: textForSpan(input, node.span),
    span: node.span,
    record: extractRecord(input, childByName(node, "record", null)),
  };
}
function extractRecord(input: string, node: RuleTree): DLRecord {
  return {
    type: "Record",
    text: textForSpan(input, node.span),
    span: node.span,
    ident: extractIdent(input, childByName(node, "ident", null)),
    recordAttrs: extractRecordAttrs(
      input,
      childByName(node, "recordAttrs", null)
    ),
  };
}
function extractRecordAttrs(input: string, node: RuleTree): DLRecordAttrs {
  return {
    type: "RecordAttrs",
    text: textForSpan(input, node.span),
    span: node.span,
    recordKeyValue: childrenByName(node, "recordKeyValue").map((child) =>
      extractRecordKeyValue(input, child)
    ),
    placeholder: childrenByName(node, "placeholder").map((child) =>
      extractPlaceholder(input, child)
    ),
    commaSpace: childrenByName(node, "commaSpace").map((child) =>
      extractCommaSpace(input, child)
    ),
  };
}
function extractRecordKeyValue(
  input: string,
  node: RuleTree
): DLRecordKeyValue {
  return {
    type: "RecordKeyValue",
    text: textForSpan(input, node.span),
    span: node.span,
    ident: extractIdent(input, childByName(node, "ident", null)),
    term: extractTerm(input, childByName(node, "term", null)),
  };
}
function extractRule(input: string, node: RuleTree): DLRule {
  return {
    type: "Rule",
    text: textForSpan(input, node.span),
    span: node.span,
    record: extractRecord(input, childByName(node, "record", null)),
    disjunct: childrenByName(node, "disjunct").map((child) =>
      extractDisjunct(input, child)
    ),
  };
}
function extractSpaces(input: string, node: RuleTree): DLSpaces {
  return {
    type: "Spaces",
    text: textForSpan(input, node.span),
    span: node.span,
  };
}
function extractStatement(input: string, node: RuleTree): DLStatement {
  const child = node.children[0];
  switch (child.name) {
    case "rule": {
      return extractRule(input, child);
    }
    case "fact": {
      return extractFact(input, child);
    }
    case "query": {
      return extractQuery(input, child);
    }
    case "deleteFact": {
      return extractDeleteFact(input, child);
    }
    case "tableDecl": {
      return extractTableDecl(input, child);
    }
    case "loadStmt": {
      return extractLoadStmt(input, child);
    }
  }
}
function extractString(input: string, node: RuleTree): DLString {
  return {
    type: "String",
    text: textForSpan(input, node.span),
    span: node.span,
    stringChar: childrenByName(node, "stringChar").map((child) =>
      extractStringChar(input, child)
    ),
  };
}
function extractStringChar(input: string, node: RuleTree): DLStringChar {
  return {
    type: "StringChar",
    text: textForSpan(input, node.span),
    span: node.span,
  };
}
function extractTableDecl(input: string, node: RuleTree): DLTableDecl {
  return {
    type: "TableDecl",
    text: textForSpan(input, node.span),
    span: node.span,
    tableKW: extractTableKW(input, childByName(node, "tableKW", null)),
    name: extractIdent(input, childByName(node, "ident", "name")),
  };
}
function extractTableKW(input: string, node: RuleTree): DLTableKW {
  return {
    type: "TableKW",
    text: textForSpan(input, node.span),
    span: node.span,
  };
}
function extractTerm(input: string, node: RuleTree): DLTerm {
  const child = node.children[0];
  switch (child.name) {
    case "record": {
      return extractRecord(input, child);
    }
    case "int": {
      return extractInt(input, child);
    }
    case "var": {
      return extractVar(input, child);
    }
    case "string": {
      return extractString(input, child);
    }
    case "bool": {
      return extractBool(input, child);
    }
    case "array": {
      return extractArray(input, child);
    }
    case "dict": {
      return extractDict(input, child);
    }
    case "placeholder": {
      return extractPlaceholder(input, child);
    }
  }
}
function extractVar(input: string, node: RuleTree): DLVar {
  return {
    type: "Var",
    text: textForSpan(input, node.span),
    span: node.span,
    alphaNum: childrenByName(node, "alphaNum").map((child) =>
      extractAlphaNum(input, child)
    ),
  };
}
export const GRAMMAR: Grammar = {
  main: {
    type: "Sequence",
    items: [
      {
        type: "Ref",
        captureName: null,
        rule: "ws",
      },
      {
        type: "RepSep",
        rep: {
          type: "Ref",
          captureName: null,
          rule: "statement",
        },
        sep: {
          type: "Ref",
          captureName: null,
          rule: "ws",
        },
      },
      {
        type: "Ref",
        captureName: null,
        rule: "ws",
      },
    ],
  },
  statement: {
    type: "Choice",
    choices: [
      {
        type: "Ref",
        captureName: null,
        rule: "rule",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "fact",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "query",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "deleteFact",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "tableDecl",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "loadStmt",
      },
    ],
  },
  comment: {
    type: "Sequence",
    items: [
      {
        type: "Text",
        value: "#",
      },
      {
        type: "RepSep",
        rep: {
          type: "Ref",
          captureName: null,
          rule: "commentChar",
        },
        sep: {
          type: "Text",
          value: "",
        },
      },
    ],
  },
  tableDecl: {
    type: "Sequence",
    items: [
      {
        type: "Ref",
        captureName: null,
        rule: "tableKW",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "ws",
      },
      {
        type: "Ref",
        captureName: "name",
        rule: "ident",
      },
    ],
  },
  loadStmt: {
    type: "Sequence",
    items: [
      {
        type: "Ref",
        captureName: null,
        rule: "loadKW",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "ws",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "path",
      },
    ],
  },
  query: {
    type: "Sequence",
    items: [
      {
        type: "Ref",
        captureName: null,
        rule: "record",
      },
      {
        type: "Text",
        value: "?",
      },
    ],
  },
  fact: {
    type: "Sequence",
    items: [
      {
        type: "Ref",
        captureName: null,
        rule: "record",
      },
      {
        type: "Text",
        value: ".",
      },
    ],
  },
  deleteFact: {
    type: "Sequence",
    items: [
      {
        type: "Text",
        value: "-",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "record",
      },
      {
        type: "Text",
        value: ".",
      },
    ],
  },
  rule: {
    type: "Sequence",
    items: [
      {
        type: "Ref",
        captureName: null,
        rule: "record",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "ws",
      },
      {
        type: "Text",
        value: ":-",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "ws",
      },
      {
        type: "RepSep",
        rep: {
          type: "Ref",
          captureName: null,
          rule: "disjunct",
        },
        sep: {
          type: "Sequence",
          items: [
            {
              type: "Ref",
              captureName: null,
              rule: "ws",
            },
            {
              type: "Text",
              value: "|",
            },
            {
              type: "Ref",
              captureName: null,
              rule: "ws",
            },
          ],
        },
      },
      {
        type: "Text",
        value: ".",
      },
    ],
  },
  disjunct: {
    type: "RepSep",
    rep: {
      type: "Ref",
      captureName: null,
      rule: "conjunct",
    },
    sep: {
      type: "Sequence",
      items: [
        {
          type: "Ref",
          captureName: null,
          rule: "ws",
        },
        {
          type: "Text",
          value: "&",
        },
        {
          type: "Ref",
          captureName: null,
          rule: "ws",
        },
      ],
    },
  },
  conjunct: {
    type: "Choice",
    choices: [
      {
        type: "Ref",
        captureName: null,
        rule: "record",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "comparison",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "arithmetic",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "negation",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "aggregation",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "placeholder",
      },
    ],
  },
  negation: {
    type: "Sequence",
    items: [
      {
        type: "Text",
        value: "!",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "record",
      },
    ],
  },
  aggregation: {
    type: "Sequence",
    items: [
      {
        type: "Ref",
        captureName: "aggregation",
        rule: "ident",
      },
      {
        type: "Text",
        value: "[",
      },
      {
        type: "RepSep",
        rep: {
          type: "Ref",
          captureName: null,
          rule: "var",
        },
        sep: {
          type: "Ref",
          captureName: null,
          rule: "commaSpace",
        },
      },
      {
        type: "Text",
        value: ":",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "ws",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "record",
      },
      {
        type: "Text",
        value: "]",
      },
    ],
  },
  comparison: {
    type: "Sequence",
    items: [
      {
        type: "Ref",
        captureName: "left",
        rule: "term",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "ws",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "comparisonOp",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "ws",
      },
      {
        type: "Ref",
        captureName: "right",
        rule: "term",
      },
    ],
  },
  comparisonOp: {
    type: "Choice",
    choices: [
      {
        type: "Text",
        value: "<=",
      },
      {
        type: "Text",
        value: ">=",
      },
      {
        type: "Text",
        value: ">",
      },
      {
        type: "Text",
        value: "<",
      },
      {
        type: "Text",
        value: "=",
      },
      {
        type: "Text",
        value: "!=",
      },
    ],
  },
  arithmetic: {
    type: "Choice",
    choices: [
      {
        type: "Ref",
        captureName: null,
        rule: "assignmentOnLeft",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "assignmentOnRight",
      },
    ],
  },
  assignmentOnRight: {
    type: "Sequence",
    items: [
      {
        type: "Ref",
        captureName: "left",
        rule: "term",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "ws",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "arithmeticOp",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "ws",
      },
      {
        type: "Ref",
        captureName: "right",
        rule: "term",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "ws",
      },
      {
        type: "Text",
        value: "=",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "ws",
      },
      {
        type: "Ref",
        captureName: "result",
        rule: "term",
      },
    ],
  },
  assignmentOnLeft: {
    type: "Sequence",
    items: [
      {
        type: "Ref",
        captureName: "result",
        rule: "term",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "ws",
      },
      {
        type: "Text",
        value: "=",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "ws",
      },
      {
        type: "Ref",
        captureName: "left",
        rule: "term",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "ws",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "arithmeticOp",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "ws",
      },
      {
        type: "Ref",
        captureName: "right",
        rule: "term",
      },
    ],
  },
  arithmeticOp: {
    type: "Choice",
    choices: [
      {
        type: "Text",
        value: "+",
      },
      {
        type: "Text",
        value: "*",
      },
      {
        type: "Text",
        value: "-",
      },
    ],
  },
  term: {
    type: "Choice",
    choices: [
      {
        type: "Ref",
        captureName: null,
        rule: "record",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "int",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "var",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "string",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "bool",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "array",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "dict",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "placeholder",
      },
    ],
  },
  var: {
    type: "Sequence",
    items: [
      {
        type: "Char",
        rule: {
          type: "Range",
          from: "A",
          to: "Z",
        },
      },
      {
        type: "RepSep",
        rep: {
          type: "Choice",
          choices: [
            {
              type: "Char",
              rule: {
                type: "Range",
                from: "A",
                to: "Z",
              },
            },
            {
              type: "Ref",
              captureName: null,
              rule: "alphaNum",
            },
          ],
        },
        sep: {
          type: "Text",
          value: "",
        },
      },
    ],
  },
  record: {
    type: "Sequence",
    items: [
      {
        type: "Ref",
        captureName: null,
        rule: "ident",
      },
      {
        type: "Text",
        value: "{",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "ws",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "recordAttrs",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "ws",
      },
      {
        type: "Text",
        value: "}",
      },
    ],
  },
  dict: {
    type: "Sequence",
    items: [
      {
        type: "Text",
        value: "{",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "ws",
      },
      {
        type: "RepSep",
        rep: {
          type: "Ref",
          captureName: null,
          rule: "dictKeyValue",
        },
        sep: {
          type: "Ref",
          captureName: null,
          rule: "commaSpace",
        },
      },
      {
        type: "Ref",
        captureName: null,
        rule: "ws",
      },
      {
        type: "Text",
        value: "}",
      },
    ],
  },
  dictKeyValue: {
    type: "Sequence",
    items: [
      {
        type: "Ref",
        captureName: "key",
        rule: "string",
      },
      {
        type: "Text",
        value: ":",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "ws",
      },
      {
        type: "Ref",
        captureName: "value",
        rule: "term",
      },
    ],
  },
  recordAttrs: {
    type: "RepSep",
    rep: {
      type: "Choice",
      choices: [
        {
          type: "Ref",
          captureName: null,
          rule: "recordKeyValue",
        },
        {
          type: "Ref",
          captureName: null,
          rule: "placeholder",
        },
      ],
    },
    sep: {
      type: "Ref",
      captureName: null,
      rule: "commaSpace",
    },
  },
  recordKeyValue: {
    type: "Sequence",
    items: [
      {
        type: "Ref",
        captureName: null,
        rule: "ident",
      },
      {
        type: "Text",
        value: ":",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "ws",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "term",
      },
    ],
  },
  int: {
    type: "Sequence",
    items: [
      {
        type: "Choice",
        choices: [
          {
            type: "Text",
            value: "-",
          },
          {
            type: "Text",
            value: "",
          },
        ],
      },
      {
        type: "Ref",
        captureName: "first",
        rule: "num",
      },
      {
        type: "RepSep",
        rep: {
          type: "Ref",
          captureName: null,
          rule: "num",
        },
        sep: {
          type: "Text",
          value: "",
        },
      },
    ],
  },
  bool: {
    type: "Choice",
    choices: [
      {
        type: "Text",
        value: "true",
      },
      {
        type: "Text",
        value: "false",
      },
    ],
  },
  array: {
    type: "Sequence",
    items: [
      {
        type: "Text",
        value: "[",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "ws",
      },
      {
        type: "RepSep",
        rep: {
          type: "Ref",
          captureName: null,
          rule: "term",
        },
        sep: {
          type: "Ref",
          captureName: null,
          rule: "commaSpace",
        },
      },
      {
        type: "Ref",
        captureName: null,
        rule: "ws",
      },
      {
        type: "Text",
        value: "]",
      },
    ],
  },
  tableKW: {
    type: "Text",
    value: ".table",
  },
  loadKW: {
    type: "Text",
    value: ".load",
  },
  ident: {
    type: "Sequence",
    items: [
      {
        type: "Ref",
        captureName: null,
        rule: "alpha",
      },
      {
        type: "RepSep",
        rep: {
          type: "Choice",
          choices: [
            {
              type: "Ref",
              captureName: null,
              rule: "alphaNum",
            },
            {
              type: "Text",
              value: ".",
            },
          ],
        },
        sep: {
          type: "Text",
          value: "",
        },
      },
    ],
  },
  string: {
    type: "Sequence",
    items: [
      {
        type: "Text",
        value: '"',
      },
      {
        type: "RepSep",
        rep: {
          type: "Ref",
          captureName: null,
          rule: "stringChar",
        },
        sep: {
          type: "Text",
          value: "",
        },
      },
      {
        type: "Text",
        value: '"',
      },
    ],
  },
  stringChar: {
    type: "Choice",
    choices: [
      {
        type: "Char",
        rule: {
          type: "Not",
          rule: {
            type: "Literal",
            value: '"',
          },
        },
      },
      {
        type: "Sequence",
        items: [
          {
            type: "Char",
            rule: {
              type: "Literal",
              value: "\\",
            },
          },
          {
            type: "Char",
            rule: {
              type: "Literal",
              value: '"',
            },
          },
        ],
      },
    ],
  },
  alpha: {
    type: "Choice",
    choices: [
      {
        type: "Char",
        rule: {
          type: "Range",
          from: "a",
          to: "z",
        },
      },
      {
        type: "Char",
        rule: {
          type: "Range",
          from: "A",
          to: "Z",
        },
      },
      {
        type: "Text",
        value: "_",
      },
    ],
  },
  num: {
    type: "Char",
    rule: {
      type: "Range",
      from: "0",
      to: "9",
    },
  },
  alphaNum: {
    type: "Choice",
    choices: [
      {
        type: "Ref",
        captureName: null,
        rule: "alpha",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "num",
      },
    ],
  },
  ws: {
    type: "RepSep",
    rep: {
      type: "Sequence",
      items: [
        {
          type: "Ref",
          captureName: null,
          rule: "spaces",
        },
        {
          type: "Choice",
          choices: [
            {
              type: "Ref",
              captureName: null,
              rule: "comment",
            },
            {
              type: "Text",
              value: "",
            },
          ],
        },
      ],
    },
    sep: {
      type: "Text",
      value: "\n",
    },
  },
  spaces: {
    type: "RepSep",
    rep: {
      type: "Text",
      value: " ",
    },
    sep: {
      type: "Text",
      value: "",
    },
  },
  placeholder: {
    type: "Text",
    value: "???",
  },
  commaSpace: {
    type: "Sequence",
    items: [
      {
        type: "Text",
        value: ",",
      },
      {
        type: "Ref",
        captureName: null,
        rule: "ws",
      },
    ],
  },
  path: {
    type: "RepSep",
    rep: {
      type: "Ref",
      captureName: null,
      rule: "pathSegment",
    },
    sep: {
      type: "Text",
      value: "/",
    },
  },
  pathSegment: {
    type: "RepSep",
    rep: {
      type: "Choice",
      choices: [
        {
          type: "Char",
          rule: {
            type: "Range",
            from: "a",
            to: "z",
          },
        },
        {
          type: "Char",
          rule: {
            type: "Range",
            from: "A",
            to: "Z",
          },
        },
        {
          type: "Char",
          rule: {
            type: "Range",
            from: "0",
            to: "9",
          },
        },
        {
          type: "Char",
          rule: {
            type: "Literal",
            value: "_",
          },
        },
        {
          type: "Char",
          rule: {
            type: "Literal",
            value: "-",
          },
        },
        {
          type: "Char",
          rule: {
            type: "Literal",
            value: ".",
          },
        },
      ],
    },
    sep: {
      type: "Text",
      value: "",
    },
  },
  commentChar: {
    type: "Char",
    rule: {
      type: "Not",
      rule: {
        type: "Literal",
        value: "\n",
      },
    },
  },
};
