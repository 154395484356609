main {
  lock = alloc.newLock();
  block.acquireLock(lock);
  forkTo afterFork;
  t = 25;
  block.sleep(t);
  block.releaseLock(lock);
  goto end;
}
afterFork {
  block.acquireLock(lock);
  x5 = 10;
}
end {}
