contract(
  id:1,
  contract:and(
    pay(from:"Alice", to:"Bob", amount:100),
    delay(
      duration:30,
      contract:pay(from:"Bob", to:"Alice", amount:110)
    )
  )
).

payment(id:1, contractID:1, from:"Alice", to:"Bob", amount:100, time:0).
payment(id:2, contractID:1, from:"Bob", to:"Alice", amount:110, time:30).
