main :- repSep(block, ws).
block :- [label, ws, blockBody].
blockBody :- ["{", ws, repSep(instr,[";", ws]), ws, "}"].
label :- ident.
instr :- (valueInstr | gotoInstr | forkToInstr).
forkToInstr :- [forkToKW, ws, label].
valueInstr :- [([ident, ws, "=", ws] | ""), rvalue].
rvalue :- (call | const).
call :- [ident, (params | "")].
gotoInstr :- [gotoKW, ws, (label | Placeholder), ([ws, ifClause] | "")].
ifClause :- [ifKW, ws, ident].
params :- ["(", repSep((ident | Placeholder), [",", ws]), ")"].
const :- (string | int | editorVar).
editorVar :- ["<<", int, ">>"].

gotoKW :- "goto".
forkToKW :- "forkTo".
ifKW :- "if".

int :- [[0-9], repSep([0-9], "")].
Placeholder :- "???".
ws :- repSep([spaces, (comment | "")], "\n").
spaces :- repSep(" ", "").
string :- ["\"", repSep(stringChar, ""), "\""].
stringChar :- (^'"' | ['\\', '"']).
alpha :- ([a-z] | [A-Z] | "_").
num :- [0-9].
alphaNum :- (alpha | num).
ident :- [alpha, repSep((alphaNum | "."), "")].
comment :- ["//", repSep(commentChar, "")].
commentChar :- ^'\n'.
