main :- repSep((ruleDefn | comment), ws).

comment :- ["#", repSep(commentChar, "")].
ruleDefn :- [ident, ws, ":-", ws, rule, "."].

rule :- (seq | choice | ref | text | charRule | repSep | placeholder).

seq :- ["[", ws, repSep(rule, [ws, ",", ws]), ws, "]"].
choice :- ["(", ws, repSep(rule, [ws, "|", ws]), ws, ")"].
ref :- [([captureName, ":"] | ""), ruleName].
captureName :- ident.
ruleName :- ident.
text :- ["\"", repSep(stringChar, ""), "\""].
repSep :- [repSepKW, "(", rep:rule, commaSpace, sep:rule, ")"].
repSepKW :- "repSep".

charRule :- (charRange | notChar | singleChar | anyChar).
charRange :- ["[", from:alphaNum, "-", to:alphaNum, "]"].
notChar :- ['^', charRule].
singleChar :- ["'", (['\\', 'n'] | ['\\', '\\'] | .), "'"].
anyChar :- ".".

ident :- repSep(alpha, "").
ws :- repSep((" "|"\n"), "").
commaSpace :- [",", ws].
placeholder :- "???".

alphaNum :- (alpha | num).
alpha :- ([a-z] | [A-Z]).
num :- [0-9].
stringChar :- (^'"' | ['\\', '"']).
commentChar :- ^'\n'.
