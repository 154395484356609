CREATE TABLE users (
  id INT,
  name TEXT
);

CREATE TABLE posts (
  id INT,
  user_id INT REFERENCES users (id),
  body TEXT
);

SELECT id FROM users;
SELECT id, ??? FROM posts;
